/* -Single company detail view
---------------------------------------------------------------------------- */
.company-detail {
  @include section-margin;

  max-width: $company-info-width;
}

.company-detail-back-link {
  font-size: rem(14px);
}
