/* -'Key figure' block
-----------------------------------------------------------------------------*/
@use "sass:math";

.key-figure-blocks-wrap {
  @include section-margin;
}
.key-figure-block {
  text-align: center;
}
.key-figure-block-inner {
  @include shadow(1);

  width: 125px;
  min-height: 125px;
  padding: 15px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: $color-body-foreground;
  background-color: $color-gray-light;

  // Colors
  .key-figure-blocks-color--no-color & {
    @include shadow(1, #555);
  }
  .key-figure-blocks-color--gray & {
    @include color-theme-brand-gray;
  }
  .key-figure-blocks-color--yellow & {
    @include color-theme-brand-yellow;
  }
  .key-figure-blocks-color--orange & {
    @include color-theme-brand-orange;
  }
  .key-figure-blocks-color--green & {
    @include color-theme-brand-green;
  }
  .key-figure-blocks-color--blue & {
    @include color-theme-brand-blue;
  }
  .key-figure-blocks-color--pink & {
    @include color-theme-brand-pink;
  }
  .key-figure-blocks-color--black & {
    @include color-theme-black;
  }
}

.key-figure-block-fact {
  margin-top: 5px;
  text-transform: uppercase;
  font-weight: $font-weight-light;
  font-size: rem(12px);

  @include media-min(rem(1250px)) {
    font-size: rem(18px);
  }
}
.key-figure-block-number {
  font-weight: $font-weight-bold;
  font-size: rem(22px);
  line-height: 1;

  &.key-figure-block-number--plus {
    &::before {
      content: '+ ';
    }
  }
  .no-js & {
    display: none;
  }
}

.key-figure-block-number--no-js {
  display: none;

  .no-js & {
    display: block;
  }
}

.key-figure-blocks {
  @include grid-container;
  @include grid-pull-bottom;
}
.key-figure-blocks--cols-1,
.key-figure-blocks--cols-3 {
  justify-content: center;
}

.key-figure-block {
  @include grid-item(1*0.5);
  @include grid-margin-bottom;
}

@include media-min(rem(375px)) {
  .key-figure-block-inner {
    width: 160px;
    min-height: 160px;
  }
  .key-figure-block-number {
    font-size: rem(24px);
  }
  .key-figure-block-fact {
    font-size: rem(14px);
  }
}

@include media-min(rem(460px)) {
  .key-figure-block-inner {
    padding: 30px;
    width: 180px;
    min-height: 180px;
  }
  .key-figure-block-fact {
    font-size: rem(16px);
  }
}

@include media-min(rem(500px)) {
  .key-figure-blocks-wrap--cols-2,
  .key-figure-blocks-wrap--cols-3,
  .key-figure-blocks-wrap--cols-4 {
    @include wrap-custom(rem(430px));
  }
  .key-figure-block-number {
    font-size: rem(24px);
  }
}

@include media-min(rem(800px)) {
  .key-figure-block-number {
    font-size: rem(32px);
  }
}

@include media-min(rem(950px)) {
  .key-figure-blocks-wrap--cols-3 {
    @include wrap-custom(rem(610px));
  }
  .key-figure-blocks--cols-3 {
    .key-figure-block {
      @include grid-item-size(math.div(1, 3));
    }
  }
  .key-figure-blocks-wrap--cols-4 {
    @include wrap-custom(rem(920px));
  }
  .key-figure-blocks--cols-4 {
    .key-figure-block {
      @include grid-item-size(1*0.25);
    }
  }
  .key-figure-block-inner {
    width: 170px;
    min-height: 170px;
  }
  .key-figure-block-fact {
    font-size: rem(14px);
  }
}

@include media-min(rem(1250px)) {
  .key-figure-blocks-wrap--cols-2 {
    @include wrap-custom(rem(530px));
  }
  .key-figure-blocks-wrap--cols-3 {
    @include wrap-custom(rem(820px));
  }
  .key-figure-blocks-wrap--cols-4 {
    @include wrap-wide;
  }
  .key-figure-block-inner {
    width: 240px;
    min-height: 240px;
  }
  .key-figure-block-number {
    font-size: rem(44px);
  }
  .key-figure-block-fact {
    font-size: rem(18px);
  }
}
