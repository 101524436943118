/* -Card slider/scroller
---------------------------------------------------------------------------- */

/* ------------------- Flickity base styles ------------------- */
// Adjusted version of the default Flickity CSS.
// Flickity v2.2.0 https://flickity.metafizzy.co

.flickity-enabled {
  position: relative;

  .hide-focus &:focus {
    outline: 0;
  }
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

// Draggable
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }
  .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
}

// flickity-button
.flickity-button {
  position: absolute;
  padding: 0;
  border: 0;
  border-radius: 0;
  background: #333;
  color: #fff;
  transition: opacity 0.2s ease;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background: lighten(#333, 15%);
    }
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 5px $color-action;
    }
  }
  &:disabled {
    opacity: 0;
    cursor: auto;
    // prevent disabled button from capturing pointer up event. Issue 716.
    pointer-events: none;
  }
}
.flickity-button-icon {
  fill: currentColor;
}

// Previous/next buttons
.flickity-prev-next-button {
  top: 50%;
  width: 50px;
  height: 100px;
  /* vertically center */
  transform: translateY(-50%);

  &.previous {
    left: 0;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  &.next {
    right: 0;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  // Right to left
  /* .flickity-rtl &.previous {
    left: auto;
    right: 10px;
  }
  .flickity-rtl &.next {
    right: auto;
    left: 10px;
  } */
  .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
}

// Page dots
/* .flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;

  // .flickity-rtl & { direction: rtl; }
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;

    &.is-selected {
      opacity: 1;
    }
  }
} */

/* flickity-fade */
.flickity-enabled.is-fade .flickity-slider {
  > * {
    pointer-events: none;
    z-index: 0;
  }
  > .is-selected {
    pointer-events: auto;
    z-index: 1;
  }
}

/* ------------------- Specifics ------------------- */
.card-slider-container {
  background: $color-section-gray;
}

.card-slider-top {
  position: absolute;
  bottom: 20px;
  width: $card-slider-top-width;
  margin: 0;
  transform: rotate(-90deg) translateY(100%);
  transform-origin: 0 100%;

  & + .page-card {
    margin-left: rem(50px);
  }
  .flickity-enabled & {
    bottom: 0;
    & + .page-card {
      margin-left: 0;
    }
  }
  .flickity-enabled.event-slider & {
    // Position at the same baseline as the cards, not the theme mark.
    bottom: $card-slider-theme-mark-height;
  }
}
.card-slider-title {
  margin-bottom: 0;
  font-size: rem(28px);
}

.news-events-selection {
  .card-slider-title {
    position: absolute;
    bottom: 20px;
    width: $card-slider-title-width;
    transform: rotate(-90deg) translateY(100%);
    transform-origin: 0 100%;

    & + .page-card {
      margin-left: rem(50px);
    }
  }
  .flickity-enabled {
    .card-slider-title {
      bottom: 0;

      & + .page-card {
        margin-left: 0;
      }
    }
  }
}


 .card-slider-tag {
  @include h;

  font-size: rem(14px);
}

.card-slider {
  position: relative;
  white-space: nowrap;
  overflow-x: auto;
  padding: 20px 30px;

  & + & {
    // Don't use mixin, don't want it responsive
    margin-top: $section-spacing;
  }
  &.flickity-enabled {
    padding: 0;
    overflow: visible;
  }
  .page-card {
    // Using padding on last item for spacing at the end of the slider since
    // margin doesn't affect it. The padding can't affect the width.
    box-sizing: content-box;
    display: inline-block;
    display: inline-flex;
    vertical-align: top;
    flex-shrink: 0;
    width: 350px;
    min-height: 100%;
    margin-right: 30px;
    white-space: normal;
  }
}
.card-slider-spacer {
  width: $card-slider-spacer-width;

  // The last card has margin that adds to the space
  &:last-child {
    width: 5px;
  }
}

@include media-max(rem(600px)) {
  .flickity-prev-next-button {
    width: 40px;
    height: 80px;
  }
}

@include media-max(rem(450px)) {
  .card-slider {
    &.flickity-enabled {
      margin-bottom: 40px;
    }
    .page-card {
      width: 300px;
      margin-right: 15px;
    }
  }
  .flickity-prev-next-button {
    top: 100%;
    transform: none;
    width: 50px;
    height: 40px;
    margin-top: 10px;
  }
}

@include media-max(rem(400px)) {
  .card-slider .page-card {
    width: 78vw;
  }
}
