/* -Staff block - list of staff/person blocks
---------------------------------------------------------------------------- */
@use "sass:math";

.staff-block {
  @include section-margin-bottom;

  &:not(:first-child), .section-block & {
    @include section-margin-top;
  }
}

.staff-list-content {
  @include section-margin-bottom(1 * 0.5);

  text-align: center;

  .page-main-body--has-nav & {
    text-align: left;
  }
}

.staff-items-wrap--cols-3 {
  @include wrap-width-wide;
}

.staff-item-list {
  @include grid-container;
  @include grid-pull-bottom;

  text-align: left;

  &.staff-item-list--count-1,
  &.staff-item-list--count-2 {
    text-align: center;
  }
}
.staff-item {
  @include grid-item(1);
  @include grid-margin-bottom;

  text-align: center;

  @include media-min(rem(750px)) {
    .staff-items-wrap--cols-2 &,
    .staff-items-wrap--cols-3 & {
      @include grid-item-size(1 * 0.5);
    }
  }
  @include media-min(rem(1200px)) {
    .staff-items-wrap--cols-3 & {
      @include grid-item-size(math.div(1, 3));
    }
    // Never do three columns inside a page with a sub menu
    .page-main-body--has-nav & {
      @include grid-item-size(1 * 0.5);
    }
  }
}
