/* -General single page view elements
---------------------------------------------------------------------------- */
.page-intro-content {
  @include section-margin;
  @include large-content-text;
}
.page-intro-content-inner {
  .page-intro-content--thin & {
    max-width: $wrap-width-thin;
  }
}

.page-main-body {
  @include wrap-thin;
  @include section-margin;
}
.page-main-body--has-nav {
  @include wrap-width-wide;

  display: flex;
}
.page-main-body--no-nav {
  @include page-margin-reset;

  max-width: none;
}
.page-main-body-nav {
  @include grid-padding-right;

  flex: 0 0 auto;
  width: $wrap-width-wide - $wrap-width-thin;

  @include media-max(rem(1050px)) {
    width: rem(250px);
  }
  @include media-max(rem(900px)) {
    display: none !important;
  }
}

.page-main-text {
  @include large-content-text;
}
