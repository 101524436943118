/* Accordion block
---------------------------------------------------------------------------- */
.accordion-block-wrap {
  @include section-margin;

  // Reset page margin for news and event detail pages
  .detail-view-body & {
    @include page-margin-reset;
  }
}
.accordion-block-wrap--cols-1 {
  @include wrap-thin;
}
.accordion-block {
  display: grid;
  gap: 15px;
  align-items: start;
}

.accordion-block--cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

@include media-min(rem(900px)) {
  .accordion-block--cols-2,
  .accordion-block--cols-3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@include media-min(rem(1200px)) {
  .accordion-block--cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

/* ------------------- Accordion items ------------------- */
$accordion-padding-vertical: 29px;
$accordion-padding-vertical-small: 20px;
$accordion-padding-horizontal: 24px;
$accordion-padding-horizontal-small: 16px;
$accordion-icon-size: 30px;
$accordion-icon-size-small: 24px;

.accordion {
  border: 1px solid $color-gray-light;
  box-shadow: 0 2px 6px 0 $color-gray-light;
  border-radius: 4px;
  background-color: #fff;
}
.accordion-title {
  margin-bottom: 0;
  font-size: rem(20px);
}
.accordion-toggle {
  padding: $accordion-padding-vertical-small $accordion-padding-horizontal-small;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;

  &:hover,
  &:focus {
    .icon {
      color: $color-brand-blue;
    }
  }
  .icon {
    width: $accordion-icon-size-small;
    height: $accordion-icon-size-small;
    transition: transform 0.1s ease;

    .accordion--open & {
      transform: rotate(180deg);
    }
  }
}

.accordion-text {
  display: none;
  padding: 0 $accordion-padding-horizontal-small
    $accordion-padding-vertical-small;

  > p:last-child {
    margin-bottom: 0;
  }

  .accordion--open & {
    display: block;
  }
}

.no-js {
  .accordion-text {
    display: block;
  }
  .accordion-toggle {
    .icon {
      display: none;
    }
  }
}

@include media-min(rem(600px)) {
  .accordion-block {
    gap: 20px;
  }
  .accordion-toggle {
    padding: $accordion-padding-vertical $accordion-padding-horizontal;

    .icon {
      width: $accordion-icon-size;
      height: $accordion-icon-size;
    }
  }
  .accordion-text {
    padding: 0 $accordion-padding-horizontal $accordion-padding-vertical;
  }
}
