/* -A single news or event detail view
---------------------------------------------------------------------------- */
.detail-view-title {
  .date-badge {
    margin-right: 0.25em;

    // Add 30px for the 0.25em spacing. Can't just convert em to rem since the
    // em value is based on the heading font size.
    @include media-min(
      $wrap-width-thin + ($page-margin * 2) + $date-badge-width + rem(30px)
    ) {
      margin-left: calc(#{-$date-badge-width} - 0.25em);
    }
  }
}

.detail-view-image-container, .detail-view-content {
  @include section-margin-top(0.5);
}
.detail-view-image {
  display: block;
  position: relative;

  .date-badge {
    position: absolute;
    bottom: 0;
    right: 50px;

    @include media-max(rem(600px)) {
      right: 20px;
    }
  }
}
.detail-view-image-fallback {
  height: 120px;
  background: rgba(0, 0, 0, 0.05);

  @include media-min(rem(400px)) {
    height: 150px;
  }
}
.detail-view-image-caption {
  margin-top: 5px;
  font-size: rem(14px);

  p {
    margin-bottom: 0.5em;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

.detail-view-meta {
  display: flex;
  margin-top: $section-spacing * 0.5;
  padding-top: $section-spacing * 0.5;
  border-top: 1px solid $color-brand-gray;

  &:empty {
    display: none;
  }
}
.detail-view-tags {
  list-style: none;
  margin: 0;

  li {
    display: inline-block;
    margin: 0 1.5em 1.5em 0;
  }
  a {
    font-weight: $font-weight-semibold;
  }
}
.detail-view-date {
  margin-left: auto;
}

/* ------------------- Event specific ------------------- */
.event-info-container {
  margin: $text-spacing 0;
  padding: 5px 15px;
  background: $color-gray-light;

  p {
    margin: 5px 0;
  }

  .calendar-btn {
    margin-top: 5px;
    margin-bottom: 10px;

    .icon {
      margin-right: 5px;
    }
  }
}
.registration-errors {
  background-color: $color-error-background;
}
.event-register-table {
  margin-top: 30px;
  width: 100%;
  border: 1px solid $color-gray-light;
  border-collapse: collapse;

  th,
  td {
    text-align: left;
    padding: 10px;
  }

  thead {
    background: $color-gray-light;
    text-transform: uppercase;
  }
  tr {
    border-bottom: 1px solid $color-gray-light;
  }
}

.event-register-expand-col {
  width: 1%;
  border-left: 0;
  padding-top: 5px;
  text-align: right;
}
.event-register-attended-col {
  width: 15%;
}
.event-register-row + tr {
  display: none;
}
.event-register-row-open {
  border-bottom: 0;

  .icon--chevron-down {
    transform: rotate(180deg);
  }
}
.event-register-row-open + tr {
  display: table-row;
}

.icon--chevron-down {
  transition: all 0.2s;
}

.event-register-toggle[aria-pressed='false'] {
  width: 70px;
  background-color: $color-gray-dark;

  &:hover,
  &:active,
  &:focus {
    background-color: lighten($color-gray-dark, 1%);
  }
}
.event-register-toggle[aria-pressed='true'] {
  width: 70px;
  background-color: $color-brand-green;

  &:hover,
  &:active,
  &:focus {
    background-color: lighten($color-brand-green, 1%);
  }
}

.event-register {
  @include large-content-text;

  margin-top: $section-spacing * 0.5;
  padding-top: $section-spacing * 0.5;
  border-top: 1px solid $color-brand-gray;

  .form-field {
    @include media-min(rem(700px)) {
      @include grid-item-size(1 * 0.5);
    }
  }
  .formset--personal_form_form_set .formset__label {
    @include h;
    @include h3;

    display: block;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .form-field--gdpr,
  .form-field--allergies {
    @include grid-item-size(1);
  }
  .form-field--gdpr {
    .text {
      display: inline;

      p {
        display: inline;
      }
    }
  }
}
.event-register-title {
  margin-bottom: 15px;
}
.event-register {
  button[type="submit"] {
    @include btn-large();
  }
}

@include media-min(rem(700px)) {
  .event-info-container {
    display: flex;

    .event-info {
      margin-right: 5px;
    }
    .calendar-btn {
      flex-shrink: 0;
      align-self: flex-end;
      margin-left: auto;
    }
  }
}

