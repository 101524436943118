/* -Search form
---------------------------------------------------------------------------- */
.search-form {
  display: inline-block;
  position: relative;

  input {
    box-sizing: content-box;
    width: 350px;
    padding: 4px 5.5em 4px 1em;
  }
  button {
    position: absolute;
    top: 0;
    right: 0;
    // Input padding + 1px border
    margin: 5px 5px 0 0;
  }
}
