/* -Person block
---------------------------------------------------------------------------- */
.person {
  display: inline-flex;
  align-items: center;
  width: 100%;
  max-width: 400px;
  text-align: left;
}

.person-image {
  flex-shrink: 0;
  width: 30%;
  max-width: 110px;
  margin-right: 20px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.person-text {
  font-size: rem(14px);
}

.person-name {
  margin: 0;
  font-size: rem(18px);
  text-transform: none;

  button {
    text-align: left;

    &:hover,
    &:focus {
      .icon {
        color: $color-brand-blue;
      }
    }
  }
  .icon {
    margin-left: 5px !important;
    width: 20px;
    height: 20px;
  }
}
.person-title,
.person-email {
  margin: 0;
}
.person-title {
  word-wrap: break-word;
  hyphens: auto;
}
.person-phone {
  margin: 10px 0 0;
}
.person-phone,
.person-email {
  .icon {
    margin-right: 3px;
    width: 16px;
    height: 16px;
  }
}
