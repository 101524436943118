/* -Floating call-to-aciton button
---------------------------------------------------------------------------- */
.floating-cta {
  position: fixed;
  bottom: 10px;
  right: 10px;
  margin-left: 10px;
  z-index: $z-floating-cta;

  @include media-min(rem(600px)) {
    a {
      @include btn-large;
    }

    bottom: 20px;
    right: 20px;
    margin-left: 20px;
  }
}
