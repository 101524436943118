/* -Company filter controls
---------------------------------------------------------------------------- */
.company-filter {
  margin-bottom: $section-spacing * 0.5;

  input {
    width: 100%;
    height: auto;
    padding: 10px;

    .properties-open & {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    @include media-min(rem(600px)) {
      font-size: rem(20px);
    }
  }
}

.company-filter-pills {
  // Minimum one row of pills with their margin
  min-height: $form-control-height + em(10px);

  button {
    margin: 0 10px 10px 0;

    .icon {
      background: rgba(0, 0, 0, 0.15);
      border-radius: 50%;
    }
  }
}

.company-filter-properties {
  padding: 10px 10px 0;
  border: 1px solid $color-form-field-border;
  border-top: 0;

  .properties-closed & {
    display: none;
  }
  li {
    margin: 0 10px 10px 0;
  }
}
