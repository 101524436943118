/* -Latest news slider block
---------------------------------------------------------------------------- */
.latest-news-block {
  @include section-padding;
}

.page-main-body-inner {
  > .latest-news-block:last-child {
    @include section-margin-bottom(-1);
  }
}
