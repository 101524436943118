/* -Internal and external link blocks
---------------------------------------------------------------------------- */
.block-internal_link:last-child .link-block,
.block-external_link:last-child .link-block,
.block-content > .link-block:last-child {
  margin: 0;
}

// Button link directly following a heading is a bit tight.
h1,
h2,
h3,
h4,
h5,
h6 {
  + .link-block > .btn {
    margin-top: 1em;
  }
}

// Pull links a bit closer to preceding text.
.block-content,
.page-main-body-inner {
  > .rich-text + .link-block,
  > p + .link-block {
    position: relative;
    top: -($text-spacing * 0.5);
  }
}
