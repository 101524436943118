/* -Base
-----------------------------------------------------------------------------*/
html {
  font-size: $base-font-size;
}
body {
  background: $color-body-background;
  color: $color-body-foreground;
  font-size: 1em;
  line-height: $base-line-height;
  tab-size: 4;
}
body,
button,
input,
optgroup,
select,
textarea {
  font-family: $font-stack-main;
  color: $color-body-foreground;
}

::selection {
  background: $color-main;
  color: #fff;
  text-shadow: none;
}

/* -Text element spacing
-----------------------------------------------------------------------------*/
p,
blockquote,
dl,
ol,
ul,
pre,
table {
  margin-bottom: $text-spacing;
}
dl dd,
dl dl {
  margin-left: $text-spacing;
}

ol,
ul {
  li & {
    margin-bottom: 0;
  }
  nav & {
    margin: 0;
    list-style: none;
    list-style-image: none;
  }
  ol,
  ul {
    margin-bottom: 0;
  }
}
ul {
  list-style: disc outside;
  margin-left: 1em;

  ul {
    list-style-type: square;
    margin-left: $text-spacing;

    ul {
      list-style-type: circle;
    }
  }
}
ol {
  list-style: decimal outside;
  margin-left: 1.5em;

  ol {
    list-style-type: upper-roman;
    margin-left: $text-spacing;

    ol {
      list-style-type: upper-alpha;
    }
  }
}

/* -Text element styles
-----------------------------------------------------------------------------*/
blockquote,
q {
  quotes: none;
}
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}
blockquote {
  position: relative;
  margin-top: $text-spacing;
  margin-bottom: $text-spacing;
  padding: 0 100px;
  font-size: rem(36px);
  font-style: italic;
  font-weight: $font-weight-light;
  background: url('../img/quote.svg') no-repeat 0 5px;

  p,
  ol,
  ul {
    margin-top: 0.75em;
    margin-bottom: 0;
    line-height: 1.35;

    &:first-child {
      margin-top: 0;
    }
  }

  @include media-max(rem(850px)) {
    padding-right: 40px;
    font-size: rem(28px);
  }
  @include media-max(em(550px)) {
    padding-left: 50px;
    padding-right: 0;
    background-size: (80px * 0.5) (60px * 0.5);
    font-size: rem(24px);
  }
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  text-decoration: none;
  cursor: help;
}
cite,
dfn,
q {
  font-style: italic;
}
b,
strong,
dt,
mark,
th {
  font-weight: $font-weight-bold;
}
del {
  color: lighten($color-body-foreground, 20%);
  text-decoration: line-through;
}
ins,
mark {
  background: #ffa;
  color: $color-body-foreground;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: $font-stack-mono;
  font-size: em(14px);
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
}
small {
  font-size: em(12px);
}
sub,
sup {
  font-size: em(12px);
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
