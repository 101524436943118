// Clear contained floats
@use "sass:math";

@mixin clearfix {
  &::before,
  &::after {
    content: ' ';
    display: table;
  }
  &::after {
    clear: both;
  }
}

// Screen reader accessible text, from HTML5 Boilerplate
@mixin visuallyhidden {
  clip: rect(0 0 0 0);
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  white-space: nowrap;
}
@mixin non-visuallyhidden {
  clip: auto;
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  white-space: inherit;
}

@mixin section-margin($times: 1) {
  margin-top: $section-spacing * $times;
  margin-bottom: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    margin-top: ($section-spacing * 0.5) * $times;
    margin-bottom: ($section-spacing * 0.5) * $times;
  }
}
@mixin section-margin-top($times: 1) {
  margin-top: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    margin-top: ($section-spacing * 0.5) * $times;
  }
}
@mixin section-margin-bottom($times: 1) {
  margin-bottom: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    margin-bottom: ($section-spacing * 0.5) * $times;
  }
}
@mixin section-padding($times: 1) {
  padding-top: $section-spacing * $times;
  padding-bottom: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    padding-top: ($section-spacing * 0.5) * $times;
    padding-bottom: ($section-spacing * 0.5) * $times;
  }
}
@mixin section-padding-top($times: 1) {
  padding-top: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    padding-top: ($section-spacing * 0.5) * $times;
  }
}
@mixin section-padding-bottom($times: 1) {
  padding-bottom: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    padding-bottom: ($section-spacing * 0.5) * $times;
  }
}

// Media queries
// Defaults to width, since it's far more common than height.
@mixin media-max($max) {
  @media screen and (max-width: $max) {
    @content;
  }
}
@mixin media-min($min) {
  @media screen and (min-width: $min) {
    @content;
  }
}
@mixin media-between($max, $min) {
  @media screen and (max-width: $max) and (min-width: $min) {
    @content;
  }
}
@mixin media-max-height($max) {
  @media screen and (max-height: $max) {
    @content;
  }
}
@mixin media-min-height($min) {
  @media screen and (min-height: $min) {
    @content;
  }
}
@mixin media-between-height($max, $min) {
  @media screen and (max-height: $max) and (min-height: $min) {
    @content;
  }
}

// Layered shadow, inspired by:
// https://www.joshwcomeau.com/css/designing-shadows/
@mixin shadow($elevation: 1, $base-color: #000) {
  // prettier-ignore
  box-shadow:
    0 (0px * $elevation) (1px * $elevation) rgba($base-color, math.div(0.07, 1 + ($elevation * 0.2))),
    0 (1px * $elevation) (2px * $elevation) rgba($base-color, math.div(0.07, 1 + ($elevation * 0.2))),
    0 (2px * $elevation) (4px * $elevation) rgba($base-color, math.div(0.08, 1 + ($elevation * 0.2))),
    0 (4px * $elevation) (8px * $elevation) rgba($base-color, math.div(0.08, 1 + ($elevation * 0.2))),
    0 (8px * $elevation) (16px * $elevation) rgba($base-color, math.div(0.08, 1 + ($elevation * 0.2)));
}
