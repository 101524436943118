a:link,
a:visited {
  color: $color-gray-dark;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #888;
}

%overlay-link {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  text-indent: -999px;
  font-size: 12px;
  z-index: $z-overlay-link;

  /* A 10x10 transparent PNG; a fix for the link not being clickable in IE */
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABdJREFUeNpi/P//PwMxgHFUIX0VAgQYACK+He3OQHX/AAAAAElFTkSuQmCC') !important;
}
.overlay-link {
  @extend %overlay-link;
}
button.overlay-link {
  // Just stretching with position doesn't work everywhere for buttons
  width: 100% !important;
  height: 100% !important;
}
.no-js button.overlay-link {
  display: none !important;
}

.skiplink {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: $z-skiplink;

  &:focus {
    display: inline-block;
    padding: 5px 10px !important;
    background: $color-action !important;
    color: $color-action-foreground !important;
  }
}
