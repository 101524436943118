/* -Date like a calendar icon
---------------------------------------------------------------------------- */
.date-badge {
  display: inline-block;
  width: $date-badge-width;
  line-height: 1;
  text-align: center;

  .month,
  .day {
    display: block;
  }
  .month {
    padding: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background: $color-brand-pink;
    color: #fff;
    font-size: rem(13px);
    text-transform: uppercase;
    font-weight: $font-weight-regular;
  }
  .day {
    padding: 8px 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #fff;
    font-size: rem(20px);
    font-weight: $font-weight-bold;
  }

  @include media-max($breakpoint-date-badge-small) {
    width: $date-badge-width-small;

    .day {
      padding: 6px 0;
      font-size: rem(18px);
    }
    .month {
      padding: 2px;
      font-size: rem(12px);
    }
  }
}
.date-badge--bottom {
  .day {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
