// -------------------- Color definitions -------------------- //
$color-gray-dark: #333;
$color-gray-light: #f1f1f1;

$color-brand-yellow: #ffcc00;
$color-brand-orange: #ef7d00;
$color-brand-pink: #e50051;
$color-brand-blue: #00a3b4;
$color-brand-green: #76b82a;
$color-brand-gray: #4a4a49;

// Lightest text color on white that meets WCAG AA accessibility requirement
$color-lightest-accessible-gray-on-white: #767676;

// -------------------- Semantic assignments -------------------- //
// When possible and logical, use colors through more meaningful
// "use case" names.

// Main body colors
$color-body-foreground: $color-gray-dark;
$color-body-background: #fff;

// Disabled text
$color-disabled: $color-lightest-accessible-gray-on-white;

// Brand things like selection color
$color-main: $color-brand-blue;

// Links and main buttons
$color-action: $color-brand-pink;
$color-action-foreground: #fff;

// Page sections
$color-section-gray: $color-gray-light;

// Site footer
$color-footer-background: $color-brand-gray;
$color-footer-foreground: #fff;

// Forms
$color-form-field-border: #ccc;

// Notice bubbles
$color-notice-info: $color-gray-light;
$color-notice-service: $color-brand-yellow;

// Status
$color-error: #ed404b;
$color-error-foreground: #d71421;
$color-error-background: #fae8e6;
$color-success: #49ad5a;
$color-success-foreground: #337a3b;
$color-success-background: #e7fae6;

// Theme brand colors
@mixin color-theme-brand-yellow {
  background-color: $color-brand-yellow;
  color: $color-gray-dark;
}
@mixin color-theme-brand-orange {
  background-color: $color-brand-orange;
  color: #fff;
}
@mixin color-theme-brand-pink {
  background-color: $color-brand-pink;
  color: #fff;
}
@mixin color-theme-brand-blue {
  background-color: $color-brand-blue;
  color: #fff;
}
@mixin color-theme-brand-green {
  background-color: $color-brand-green;
  color: #fff;
}
@mixin color-theme-brand-gray {
  background-color: $color-brand-gray;
  color: #fff;
}
@mixin color-theme-black {
  background-color: #000;
  color: #fff;
}
