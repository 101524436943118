/* -Callout block
---------------------------------------------------------------------------- */
.callout-block-wrap {
  @include section-margin;

  // Must have a wrapping column flex container for IE11
  // https://github.com/philipwalton/flexbugs#flexbug-3
  display: flex;
  flex-direction: column;
}

.callout-block {
  @include shadow(3);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 30px;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
}

.callout-block-content {
  > p,
  > div {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Colors
.callout-block-color--dark {
  @include color-theme-brand-gray;

  .btn-color-default {
    @include button-light-colors;
  }
}
.callout-block-color--light {
  background-color: $color-gray-light;

  .btn-color-default {
    @include button-gray-colors;
  }
}
.callout-block-color--yellow {
  @include color-theme-brand-yellow;
}
.callout-block-color--orange {
  @include color-theme-brand-orange;
}
.callout-block-color--green {
  @include color-theme-brand-green;
}
.callout-block-color--blue {
  @include color-theme-brand-blue;
}
.callout-block-color--pink {
  @include color-theme-brand-pink;
}
.callout-block-color--black {
  @include color-theme-black;
}
