/* -Form block
---------------------------------------------------------------------------- */
.form-block {
  @include large-content-text;
}

.form-block--startup-register {
  @include media-min(rem(500px)) {
    .form-field--team_size {
      input {
        display: inline-block;
        width: 6rem;
      }
    }
  }
  @include media-min(rem(700px)) {
    .form-field--name,
    .form-field--phone,
    .form-field--email,
    .form-field--municipality {
      @include grid-item-size(1 * 0.5);
    }
  }
}
.form-block--conference-booking {
  select {
    width: 100%;
  }

  @include media-min(rem(900px)) {
    .form-field--name,
    .form-field--company,
    .form-field--phone_nr,
    .form-field--email,
    .form-field--conference_package,
    .form-field--furnishing,
    .form-field--premise,
    .form-field--participants {
      @include grid-item-size(1 * 0.5);
    }

    select {
      margin-top: 5px;
    }
  }
}

.form-block--conference-booking {
  margin-top: rem(30px);
}

#event_detail-form {
  select {
    display: block;
    min-width: 70%;
    margin-top: 0.3em;
  }

  .form-field--newsletter_company_list,
  .form-field--newsletter_non_company_list {
    @include grid-item(1);

    font-size: rem(16px);

    &:last-child {
      margin-top: 0;
    }
  }
}
