// Font stacks
// Arial is explicitly mentioned as the fallback font in the style guide
@use "sass:math";

$font-stack-main: 'Source Sans Pro', Arial, sans-serif;
// $font-stack-alt: Georgia, serif;
$font-stack-mono: Consolas, 'Andale Mono', 'Lucida Console', monospace;

// Text style
// The base font size should preferably be kept at 16px as a definition of
// the browser default and used as a base for calculation. To globally
// increase the font size on the site, set it with a rule on a containing
// element instead of changin this.
$base-font-size: 16px;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Paragraph margins and such, one for pixels and one for em. The former should
// likely only be relevant for calculations.
$base-text-spacing: $base-font-size * 1.5;
$text-spacing: em($base-text-spacing);

// $text-spacing without 'em'
$base-line-height: math.div($text-spacing, 1em);

// Heading sizes
$h1: 52px;
$h2: 36px;
$h3: 24px;
$h4: $base-font-size;
$h5: $base-font-size;
$h6: $base-font-size;

// Tighter line height for headings the larger they are
$heading-line-height: 1.25;
$h1-line-height: 1;
$h2-line-height: 1.1;

// Larger and smaller parapgraph text
$lead-text-size: 28px;
$sub-text-size: 14px;

/* ------------------- Breakpoints ------------------- */
$breakpoint-type-medium: rem(1200px);
$breakpoint-type-small: rem(500px);
$breakpoint-type-tiny: rem(380px);
