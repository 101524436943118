/* -Theme flag with text used for event
---------------------------------------------------------------------------- */
.theme-flag {
  padding: 2px 30px 4px;
  border-radius: 0 0 5px 5px;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.15) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  font-weight: $font-weight-semibold;
  font-size: rem(13px);

  p {
    margin-bottom: 0;
  }
}

.theme-flag--color-yellow {
  @include color-theme-brand-yellow;
}
.theme-flag--color-orange {
  @include color-theme-brand-orange;
}
.theme-flag--color-green {
  @include color-theme-brand-green;
}
.theme-flag--color-blue {
  @include color-theme-brand-blue;
}
.theme-flag--color-pink {
  @include color-theme-brand-pink;
}
.theme-flag--color-black {
  @include color-theme-black;
}
.theme-flag--color-gray {
  @include color-theme-brand-gray;
}

// Specifics for event detail view
.event-detail {
  .theme-flag {
    margin-left: 20px;
    width: fit-content;
  }
}

// Specifics for event page card
.page-card {
  .theme-flag {
    position: absolute;
    left: 20px;
    top: 100%;
  }
}
