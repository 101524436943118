/* -Section block, wrapping other blocks
---------------------------------------------------------------------------- */
.section-block {
  padding-top: 1px;
  padding-bottom: 1px;

  &:last-child {
    @include section-margin-bottom(-1);
  }
}

.section-block--gray {
  background-color: $color-gray-light;
}

// Section block for event and news detail pages
.detail-view-body {
  .section-block {
    @include section-margin;
    @include section-padding(0.8);

    padding-right: $page-margin-small;
    padding-left: $page-margin-small;

    > *:first-child {
      margin-top: 0 !important;
    }
    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}
