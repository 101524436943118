/* -Link with an arrow icon
---------------------------------------------------------------------------- */
a.arrow-link {
  display: inline-block;
  padding: $arrow-link-padding 0;
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    .text {
      text-decoration: underline;
    }
  }
  .icon--arrow:first-child {
    margin-right: 0.25em;
    transform: rotate(180deg);
  }
  .icon--arrow:last-child {
    margin-left: 0.25em;
  }
}
