/* Embedded video overlay
---------------------------------------------------------------------------- */
@keyframes video-overlay-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.video-overlay-play {
  padding: rem(15px) rem(25px);
  font-size: rem(20px);
}

.video-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  z-index: $z-video-overlay;
  animation: video-overlay-fade-in 0.3s;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.video-overlay-close {
  position: absolute;
  top: 0;
  right: 0;
  height: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  background: transparent !important;
  color: #fff !important;
  z-index: $z-video-overlay + 1;

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.5) !important;
  }
  .icon {
    width: 32px;
    height: 32px;
    margin: 8px !important;
  }
}
