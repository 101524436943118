/* -Pagination links
---------------------------------------------------------------------------- */
.pagination {
  @include section-margin-top;

  display: flex;
  align-items: center;
}

.pagination-info {
  margin: 0 auto 0 0;
}

.pagination-prev ~ .pagination-next {
  margin-left: 30px;
}
