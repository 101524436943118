$wrap-width: rem(940px);
$wrap-width-thin: rem(860px);
$wrap-width-wide: rem(1200px);

$page-margin: rem(50px);
$page-margin-small: $page-margin * 0.5;
$section-spacing: 60px;

$arrow-link-padding: 5px;

$date-badge-width: 3.5rem;
$date-badge-width-small: 2.5rem;

$card-slider-title-width: rem(50px);
$card-slider-top-width: rem(60px);
$card-slider-spacer-width: rem(30px);

$card-slider-theme-mark-height: rem(25px);

$company-info-width: rem(650px);

// Header
// A fixed height is set on the header elements when it's toggled since the
// menu relies on it for covering the page height (therefore the 'small'
// naming).
$logo-width: 243px;
$logo-height: 70px;
$logo-width-small: round($logo-width * 0.8);
$logo-height-small: round($logo-height * 0.8);
$logo-width-tiny: round($logo-width * 0.6);
$logo-height-tiny: round($logo-height * 0.6);

$header-secondary-small-height: rem(40px);
$header-logo-small-padding: rem(10px);
$header-primary-small-height: ($header-logo-small-padding * 2) +
  rem($logo-height-small);
$header-primary-tiny-height: ($header-logo-small-padding * 2) +
  rem($logo-height-tiny);
$header-small-height: $header-secondary-small-height +
  $header-primary-small-height;
$header-tiny-height: $header-secondary-small-height +
  $header-primary-tiny-height;

// Form elements
// Using em instead of rem so that dimensions adapt to local font size changes
$form-control-base-height: 40px;
$form-control-font-size: 16px;
$form-control-radius: 4px;
$form-field-padding: em(5px, $form-control-font-size);
$form-control-height: em($form-control-base-height, $form-control-font-size);
$button-font-size: 16px;
$button-height: em($form-control-base-height, $button-font-size);

/*-------------------- Common breakpoints --------------------*/
// Most breakpoints should be isolated to what they affect and not be some
// arbitrary values, but global changes like text size may require adjustment
// to details in some instances and should therefore be a defined variable.

// Tighten layout
$breakpoint-grid-tight: rem(700px);
$breakpoint-page-margin-small: rem(550px);

$breakpoint-date-badge-small: $breakpoint-type-small;

$breakpoint-menu-toggle: rem(1000px);

$breakpoint-logo-tiny: rem(400px);
