/* -Future events slider block
---------------------------------------------------------------------------- */
.future-events-block {
  @include section-padding;

  &.card-slider-container {
    padding-bottom: rem($section-spacing) - $card-slider-theme-mark-height;
  }
}

.page-main-body {
  .future-events-block:last-child {
    @include section-margin-bottom(-1);
  }
}
