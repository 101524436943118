/* -The main header area with logo and navigation
---------------------------------------------------------------------------- */
$header-edge-spacing: 20px;
$trigger-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
$dropdown-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

// Apply to a pseudo element to cover shadow spilling
@mixin shadow-overlay {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 10px;
  margin-top: -9px;
  background: #fff;
  z-index: 5;
}

.site-header {
  // Got some weird glitch in a dropdown menu that I can't reproduce. Hide all
  // possible br just in case, they will never be relevant inside the header.
  br {
    display: none !important;
  }
}

.site-header-main {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.site-header-logo {
  padding: 15px 0 15px 15px;
  display: flex;
  flex-shrink: 0;
}

.site-header-nav {
  flex-grow: 1;
  // Subtract main nav link + 5px from right
  padding: 10px ($header-edge-spacing - 10px + 5px) 10px 10px;
}

/* ------------------- Navigation menus ------------------- */
$sub-menu-open-delay: 75ms;
$sub-menu-close-delay: 200ms;

.header-nav {
  ul {
    text-align: right;
  }
  a {
    color: inherit;

    // Enfore middle alignment even if it's the only child
    .text {
      vertical-align: middle;
    }
  }
  .icon--search-small,
  .icon--globe {
    margin-right: 5px;
  }
  .icon--chevron-down {
    margin-left: 1px;
  }
  .sub-menu {
    text-align: left;
  }
}

.main-nav {
  position: relative;
  z-index: $z-header + 1;

  // Match the larger text size better
  .icon--chevron-down {
    width: 1em;
    height: 1em;
    margin-left: 3px;
  }
  // Secondary menu items will be available in the small screen menu
  .menu-item--secondary {
    display: none;
  }
  > ul {
    > li {
      margin-left: 20px;

      > a {
        display: inline-block;
        padding: 5px 10px;
        font-size: rem(20px);
        font-weight: $font-weight-semibold;

        .text {
          display: inline-block;
          padding: 5px 0;
          border-bottom: 2px solid transparent;
        }
        &:hover,
        &:focus {
          .text {
            border-bottom-color: currentColor;
          }
        }
        &[role='button']:hover,
        &[role='button']:focus {
          .text {
            border-bottom-color: transparent;
          }
        }
      }
      &.current-item > a .text,
      &.current-item-ancestor > a .text {
        border-bottom-color: currentColor !important;
      }
    }
  }
}

.secondary-nav {
  position: relative;
  // Subtract link padding and border + 5px
  padding: 0 ($header-edge-spacing - 12px + 5px);
  background: $color-gray-light;
  text-align: right;
  z-index: $z-header + 2;

  > ul {
    > li {
      margin-left: 10px;

      > a {
        padding: 6px 10px;

        .text {
          display: inline-block;
          padding-top: 3px;
          padding-bottom: 1px;
          border-bottom: 2px solid transparent;
          line-height: $base-line-height;
        }
        &:hover .text,
        &:focus .text {
          border-bottom-color: currentColor;
        }
      }
      &.current-item > a .text,
      &.current-item-ancestor > a .text {
        border-bottom-color: currentColor !important;
      }
    }
  }
  .sub-menu {
    a {
      max-width: 280px;
    }
  }
}

// Only do dropdowns when not in menu toggle mode
@include media-min($breakpoint-menu-toggle + rem(1px)) {
  .header-nav {
    > ul > .has-children {
      position: relative;

      > a {
        background-color: transparent;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        transition: background-color 0s ease $sub-menu-close-delay,
          box-shadow 0s ease $sub-menu-close-delay;

        // A white box to cover the dropdown shadow
        &::after {
          @include shadow-overlay;

          visibility: hidden;
          transition: visibility 0s ease $sub-menu-close-delay;
        }
        // Ensure the text is always above the white box
        .icon,
        .text {
          position: relative;
          z-index: 3;
        }
      }
      // Sub menu
      > ul {
        visibility: hidden;
        transition: visibility 0s ease $sub-menu-close-delay;

        position: absolute;
        top: 100%;
        right: -10px;
        padding: 15px;
        box-shadow: $dropdown-shadow;
        overflow: hidden;
        background: #fff;
        white-space: nowrap;
        z-index: 4;

        > li {
          width: max-content;
          min-width: 13em;
          max-width: rem(450px);
          display: inline-block;
          padding: 10px 15px;
          vertical-align: top;

          > a {
            margin-bottom: 5px;
            font-weight: $font-weight-semibold;
          }
          li {
            display: block;
            margin-top: 5px;
            margin-left: 1em;

            a {
              padding: 5px 0;
            }
          }
        }
        .title-item {
          display: block;
          font-size: rem(20px);
        }
        a {
          display: block;
          margin: 0;
          border-bottom: 1px solid transparent;
        }
        a:hover,
        a:focus,
        .current-item > a {
          border-bottom-color: currentColor;
        }
      }
      > .sub-menu--single-level {
        // Combine ul and li padding from the regular menu lists
        padding: 25px 30px;

        li {
          display: block;
          padding: 0;
        }
        li:not(.title-item) a {
          font-weight: normal;
          margin-bottom: 0;
          padding: 5px 0;
        }
      }
      // Only show menus on hover if JS is ready since single level ones may
      // look broken without a class that's added dynamically.
      .js-init &:hover > a::after,
      .js-init &:hover > ul,
      > a[aria-expanded='true']::after,
      > a[aria-expanded='true'] ~ ul {
        visibility: visible;
        transition-delay: $sub-menu-open-delay;
      }
      .js-init &:hover > a,
      > a[aria-expanded='true'] {
        box-shadow: $trigger-shadow;
        background-color: #fff;
        transition-delay: $sub-menu-open-delay;
      }
      // Remove the open delay when focusing
      > a[aria-expanded='true'] {
        &::after,
        ~ ul {
          transition-delay: 0s !important;
        }
      }
      // Disable transition until JS is ready to prevent flickering on page
      // load in Chrome.
      .no-js-init & {
        > a,
        > a::after,
        > ul {
          transition-delay: 0s !important;
          transition-duration: 0s !important;
        }
      }
    }
    > ul > .has-children.single-col > ul > li {
      display: block;
    }
    .has-children a[role='button'] {
      cursor: default;

      &:hover,
      &:focus {
        .text {
          border-bottom-color: transparent;
        }
      }
    }
    .sub-menu {
      li {
        margin: 0;
      }
      a {
        display: inline-block;
        padding: 0;
        white-space: normal;
      }
    }
  }

  .main-nav {
    > ul {
      // Visually equal padding when there is a dropdown chevron icon, increase
      // left padding and compensate with reduced margin.
      .js & > .has-children {
        margin-left: 15px;

        > a {
          padding-left: 15px;
        }
      }
    }
  }

  .secondary-nav {
    > ul {
      > li {
        // Left align the first two
        &:nth-child(-n + 2) > ul {
          right: auto;
          left: -10px;
        }
        // Center align the third and any one with a single item
        &:nth-child(3) > ul,
        > ul[data-item-count='1'] {
          right: auto;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      // Visually equal padding when there is a dropdown chevron icon, increase
      // left padding and compensate with reduced margin.
      .js & > .has-children {
        margin-left: 5px;

        > a {
          padding-left: 15px;
        }
      }
    }
  }
}

/* ------------------- Search ------------------- */
.header-search-item {
  position: relative;

  &.search-open a[role='button'] {
    background-color: #fff;
    box-shadow: $trigger-shadow;

    // A white box to cover the dropdown shadow
    &::after {
      @include shadow-overlay;
    }
    // Ensure the text is always above the white box
    .icon,
    .text {
      position: relative;
      z-index: 3;
    }
  }
}
.header-search {
  display: none;

  position: absolute;
  top: 100%;
  right: -10px;
  padding: 15px;
  background-color: #fff;
  box-shadow: $dropdown-shadow;

  .search-open & {
    display: block;
  }
}

/* ------------------- Small screen menu toggle ------------------- */
.menu-toggle {
  display: none !important;
  position: relative;
  padding-right: 35px;
  background: transparent;
  color: inherit;
  font-size: rem(20px);
  font-weight: $font-weight-semibold;

  &:hover,
  &:focus {
    background: $color-gray-light !important;
  }
}
.menu-toggle-icon {
  top: 50%;
  right: 15px;

  &,
  &::before,
  &::after {
    position: absolute;
    width: 13px;
    height: 2px;
    background-color: currentColor;
    transition: background-color 0.15s ease;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    left: 0;
    transition: transform 0.15s ease;
  }
  &::before {
    top: 0;
    transform: translateY(-5px);
  }
  &::after {
    bottom: 0;
    transform: translateY(5px);
  }
  .nav-open & {
    background-color: rgba(255, 255, 255, 0);

    &::before {
      transform: translateY(0) rotate(-225deg);
    }
    &::after {
      transform: translateY(0) rotate(225deg);
    }
  }
}

/* ------------------- Media queries ------------------- */

// Tighten up to hopefully keep at as a single row for common tablet sizes
@include media-max(rem(1050px)) {
  .secondary-nav {
    > ul {
      > li > a {
        padding-left: 5px;
        padding-right: 5px;
      }
      .js & > .has-children > a {
        padding-left: 10px;
      }
    }
  }
}

// Hide behind a menu toggle. Sets a fixed height to header elements since the
// toggled menu relies on it. Logo size is set in _logo.scss.
@include media-max($breakpoint-menu-toggle) {
  // Secondary nav bar with language and search
  .secondary-nav {
    height: $header-secondary-small-height;
    line-height: $header-secondary-small-height - rem(2px);
    padding: 0;

    .menu-item--secondary {
      display: none !important;
    }
    ul,
    li,
    a {
      height: inherit;
    }
    > ul > li > a {
      padding: 0 15px;
    }
  }

  // Primary nav menu behind a toggle
  .nav-open,
  .nav-open body {
    overflow: hidden;
  }
  .site-header-main {
    height: $header-primary-small-height;
  }
  .site-header-nav {
    padding: 5px 10px;
    text-align: right;
  }
  .menu-toggle {
    display: inline-block !important;
    display: inline-flex !important;
  }
  .main-nav {
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    height: calc(100vh - #{$header-small-height});
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.2s ease, visibility 0s ease 0.2s,
      width 0s ease 0.2s;
    text-align: right;

    .nav-open & {
      visibility: visible;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      transition-delay: 0s;
    }
    > ul {
      display: inline-block;
      width: 100%;
      max-width: 300px;
      min-height: 100%;
      background: #fff;
      transform: translateX(100%);
      transition: transform 0.2s ease;

      .nav-open & {
        transform: translateX(0);
      }
    }
    ul {
      text-align: left;
    }
    li,
    a {
      display: block !important;
      width: 100%;
      margin: 0 !important;
    }
    a {
      padding: 7px 15px !important;

      .icon {
        transition: transform 0.15s ease;
      }
    }
    .current-item > a {
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 7px;
        height: 2px;
        margin-top: -1px;
        background: currentColor;
      }
    }
    ul ul {
      a {
        padding-left: 25px !important;
      }
      .current-item > a::before {
        left: 10px;
      }
    }
    ul ul ul {
      a {
        padding-left: 35px !important;
      }
      .current-item > a::before {
        left: 20px;
      }
    }
    > ul {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    > ul > li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      > ul {
        display: none;

        > li > a {
          font-weight: $font-weight-semibold;
        }
      }
      a[aria-expanded='true'] {
        & ~ ul {
          display: block;
        }
        .icon {
          transform: rotate(180deg);
        }
      }
      > a {
        display: flex !important;
        align-items: center;

        &:hover,
        &:focus {
          .text {
            text-decoration: underline;
          }
        }
        .text {
          flex-grow: 1;
          padding: 0;
          border: 0;
        }
      }
    }
  }
}

@include media-max(rem(650px)) {
  .header-search-item {
    position: static;
  }
  .header-search {
    left: 10px;
    right: 10px;

    .search-form,
    input {
      display: block;
      width: 100%;
    }
    input {
      // Is using box-sizing content-box so 100% width won't work. Do the
      // equivalent with calc.
      width: calc(100% - 6.5em);
    }
  }
}

@include media-max($breakpoint-logo-tiny) {
  .site-header-main {
    height: $header-primary-tiny-height;
  }
  .main-nav {
    height: calc(100vh - #{$header-tiny-height});
  }
  .menu-toggle {
    font-size: rem(18px);
  }
  .main-nav > ul {
    display: block;
    max-width: none;
  }
}
