/* -Text variations
-----------------------------------------------------------------------------*/
@mixin large-content-text {
  @include media-min($breakpoint-type-small) {
    font-size: rem(18px);
  }
  @include media-min($breakpoint-type-medium) {
    font-size: rem(20px);
  }
}

/* Larger and smaller text */
%lead-text {
  margin-bottom: em($base-text-spacing, $lead-text-size);
  font-size: rem($lead-text-size);
  font-weight: $font-weight-semibold;

  @include media-max($breakpoint-type-medium) {
    font-size: rem($lead-text-size - 4px);
  }
  @include media-max($breakpoint-type-small) {
    font-size: rem($lead-text-size - 8px);
  }
  @include media-max($breakpoint-type-tiny) {
    font-size: rem($lead-text-size - 10px);
  }
}
@mixin lead-text-reset {
  margin-bottom: 0;
  font-size: 1em;
  font-weight: normal;
}

%sub-text {
  margin-bottom: em($base-text-spacing, $sub-text-size);
  font-size: rem($sub-text-size);
}

.lead-text {
  @extend %lead-text;
}
.sub-text {
  @extend %sub-text;
}

.lead-text,
.sub-text {
  p,
  ol,
  ul {
    margin-bottom: inherit;
  }
}

// Inline text alongside things like icons
.text {
  vertical-align: middle;

  // Restore default alignment if it appears on its own
  &:only-child {
    vertical-align: baseline;
  }
}

// Set based on configuration, try not to use it 'manually' in templates.
.centered {
  text-align: center;
}
