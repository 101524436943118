/* -Person information modal
---------------------------------------------------------------------------- */

.person-modal {
  padding: 25px 30px;
  width: 100%;
  border: 1px solid $color-gray-light;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
}
.person-modal-image {
  text-align: center;
  margin-bottom: 20px;

  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.person-modal-phone {
  margin-bottom: 5px;
}
.person-modal-email,
.person-modal-phone {
  .icon {
    margin-right: 3px;
    width: 16px;
    height: 16px;
  }
}

@include media-min(rem(500px)) {
  .person-modal-phone,
  .person-modal-email {
    .icon {
      margin-right: 5px;
      width: 20px;
      height: 20px;
    }
  }
}
