/* -Content columns, max three cols.
---------------------------------------------------------------------------- */
@use "sass:math";

.content-columns-block-wrap {
  @include section-margin;

  &.content-columns-block-wrap--cols-1 {
    @include edge-wrap;
  }

  // Reset page margin for news and event detail pages
  .detail-view-body & {
    @include page-margin-reset;
  }
}
.content-column {
  @include grid-margin-bottom;
}

// Multiple columns
@include media-min(rem(600px)) {
  .content-columns--cols-2 {
    @include grid-container;

    .content-column {
      @include grid-item(1*0.5);

      margin-bottom: 0;
    }
  }
}

@include media-min(rem(800px)) {
  .content-columns--cols-3 {
    @include grid-container;

    .content-column {
      @include grid-item(math.div(1, 3));

      margin-bottom: 0;
    }
  }
}

/*-Specific "Columns layout" styling for images block inside content columns-*/
$content-columns-images-block-horizontal-gutter: rem(20px);
.content-column {
  @include media-min(rem(400px)) {
    .images-block--cols-2,
    .images-block--cols-3 {
      @include grid-container($content-columns-images-block-horizontal-gutter);

      .images-block-item {
        @include grid-item-gutter-size(
          $content-columns-images-block-horizontal-gutter
        );
      }
    }
  }
}
