/* -Tag filtered content block
---------------------------------------------------------------------------- */
@use "sass:math";

$tag-filtered-content-gutter: 20px;
$tag-filtered-content-gutter-tight: math.div($tag-filtered-content-gutter, 2);

.tag-filtered-content-block {
  @include section-margin;
}
.tag-filtered-content-content {
  @include section-margin-bottom(0.5);
}
.tag-filter {
  @include section-margin-bottom(0.5);
}
.tag-filter-toggle-btn {
  .tag-filter-open & {
    margin-bottom: 8px;

    .icon {
      transform: rotate(180deg);
      transition: transform 0.2s ease-in-out;
    }
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
.tag-filter-list {
  display: none;
  flex-wrap: wrap;
  gap: 8px;

  .tag-filter-open & {
    display: flex;
  }
  li {
    flex: 0 0 auto;

    button {
      &.tag--active {
        background-color: lighten($color-gray-dark, 20%);
      }
    }
  }
}

.tag-filtered-content-items {
  display: grid;
  gap: $tag-filtered-content-gutter-tight;
  align-items: start;
}

.tag-filtered-content-item {
  padding: 20px;
  height: 100%;
  background-color: $color-gray-light;

  > p:last-child {
    margin-bottom: 0;
  }
}
.tag-filtered-content-items--cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

@include media-min($breakpoint-grid-tight + rem(1px)) {
  .tag-filtered-content-items {
    gap: $tag-filtered-content-gutter;
  }
}

@include media-min(rem(600px)) {
  .tag-filter-list {
    gap: 16px;

    button {
      @include button;
    }
  }
  .tag-filtered-content-item {
    padding: 30px;
  }

  .tag-filtered-content-items--cols-2,
  .tag-filtered-content-items--cols-3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@include media-min(rem(800px)) {
  .tag-filter-toggle-btn {
    display: none;
  }
  .tag-filter-list {
    display: flex;
  }

  .tag-filtered-content-items--cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.tag-filtered-content-item {
  &.hidden {
    display: none;
  }
}
