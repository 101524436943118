/* -Newsletter form block
---------------------------------------------------------------------------- */
.newsletter-block {
  @include section-padding;
  @include large-content-text;

  background: $color-section-gray;
  text-align: center;
}

.newsletter-block-content {
  margin-bottom: $text-spacing;
}

.newsletter-form {
  @include large-content-text;

  text-align: center;

  // Field styles
  input,
  button {
    font-size: inherit;
  }
  .form-field {
    margin-top: 5px;
  }
  .field-group-label {
    font-weight: $font-weight-semibold;

    @include media-max(rem(550px)) {
      display: block;
    }
  }
  .radio-field {
    display: inline-block;
    margin-left: 1.25em;

    .field-error {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }
  .form-field--email {
    input {
      height: $form-control-height + em(6px);
      padding-left: 10px;
      padding-right: 8em;

      @include media-min(rem(400px)) {
        padding-left: 50px;
        // Background properties are set in _forms due to the error icon, be sure
        // to fully override.
        background-image: url('../img/paper-plane.svg') !important;
        background-position: 13px 50% !important;
        background-repeat: no-repeat !important;
        background-size: auto !important;
      }
    }
    label {
      @include visuallyhidden;
    }
  }

  // Positioning. Pretty convoluted due to the submit button's position
  // 'inside' the email field.
  form,
  .form-fields {
    position: relative;
  }
  .form-footer {
    position: absolute;
    // The font-size with line-height of the email input's .field-error
    bottom: rem(14px * 1.5);
    right: 0;
    width: 100%;
    // Half of the email field's extra height + the email input's field-error
    // top margin
    margin-bottom: em(3px + 2px);
    pointer-events: none;
    text-align: right;
  }
  .form-actions {
    display: inline-block;
    pointer-events: auto;

    button {
      height: $form-control-height - em(4px);
      line-height: $form-control-height - em(6px);
      // Half of the reduced height
      margin-bottom: em(2px);
      // Half of the reduced height + half of the email field's extra height
      margin-right: em(2px + 3px);
    }
  }
  // Hide it visually since it can't be easily positioned and isn't very useful
  // anyway due to the small number of fields.
  .form-error-small {
    @include visuallyhidden;
  }

  .form-no-js & {
    padding-bottom: 4em;
  }
  .form-field--honeypot {
    position: absolute;
    top: 100%;
    left: -$grid-gutter;

    @include when-grid-tight {
      left: -$grid-gutter-tight;
    }

    input {
      display: inline-block;
      width: 5em;
      margin-left: 0.25em;
    }
  }
}
