/* Icons
---------------------------------------------------------------------------- */
/* GENERATED, DO NOT EDIT MANUALLY */

$icon-base-width: 16px;
$icon-base-height: 16px;
.icon {
  width: $icon-base-width;
  height: $icon-base-height;
  flex-grow: 0;
  flex-shrink: 0;
}

$icon-arrow-width: 27px;
$icon-arrow-height: 17px;
.icon--arrow {
  width: $icon-arrow-width;
  height: $icon-arrow-height;
}

$icon-calendar-width: 13px;
$icon-calendar-height: 12px;
.icon--calendar {
  width: $icon-calendar-width;
  height: $icon-calendar-height;
}

$icon-chevron-down-width: 16px;
$icon-chevron-down-height: 16px;
// Size matches base icon size, no rule generated

$icon-cross-width: 16px;
$icon-cross-height: 16px;
// Size matches base icon size, no rule generated

$icon-globe-width: 16px;
$icon-globe-height: 16px;
// Size matches base icon size, no rule generated

$icon-play-width: 16px;
$icon-play-height: 16px;
// Size matches base icon size, no rule generated

$icon-search-small-width: 16px;
$icon-search-small-height: 16px;
// Size matches base icon size, no rule generated

$icon-search-width: 24px;
$icon-search-height: 24px;
.icon--search {
  width: $icon-search-width;
  height: $icon-search-height;
}

$icon-slider-arrow-width: 13px;
$icon-slider-arrow-height: 27px;
.icon--slider-arrow {
  width: $icon-slider-arrow-width;
  height: $icon-slider-arrow-height;
}

$icon-envelope-width: 24px;
$icon-envelope-height: 24px;
.icon--envelope {
  width: $icon-envelope-width;
  height: $icon-envelope-height;
}

$icon-phone-width: 24px;
$icon-phone-height: 24px;
.icon--phone {
  width: $icon-phone-width;
  height: $icon-phone-height;
}