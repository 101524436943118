/* -The global footer area
-----------------------------------------------------------------------------*/

/* ------------------- Content ------------------- */
.site-footer-content {
  padding-top: 30px;
  padding-bottom: 30px;
  background: $color-footer-background;
  color: $color-footer-foreground;

  a {
    color: inherit;
  }
}
.site-footer-content-inner {
  display: flex;
  align-items: center;
}
.site-footer-text {
  flex-grow: 1;
  margin-right: 2em;
}
.site-footer-logo {
  flex: 0 0 auto;
}
.site-footer-social {
  margin-top: 20px;
}
.site-footer-social-icons {
  margin-top: 0.5em;
  display: flex;
  gap: 1em;
  align-items: center;

  a {
    width: 40px;

    &:last-child {
      width: 42px;
    }
  }
}

@include media-max(rem(700px)) {
  .site-footer-content-inner {
    flex-direction: column-reverse;
  }
  .site-footer-text {
    margin-right: 0;
    margin-top: 1em;
    font-size: rem(14px);
    text-align: center;
  }
  .site-footer-social {
    text-align: center;
  }
  .site-footer-social-icons {
    justify-content: center;

    a {
      width: 30px;

      &:last-child {
        width: 32px;
      }
    }
  }
}

/* ------------------- Financer logos ------------------- */
.site-footer-financers {
  display: flex;
  justify-content: space-between;

  img {
    flex: 0 1 auto;
    max-width: 200px;
    max-height: 70px;
    margin: 20px 15px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @include media-max(1250px) {
    flex-wrap: wrap;

    // 3 columns
    img {
      flex-basis: 27%;
      max-width: 270px;

      &:nth-child(3n + 1) {
        margin-left: 0;
      }
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
  }
  @include media-max(900px) {
    img {
      max-width: 27%;
    }
  }
  @include media-max(500px) {
    // 2 columns
    img {
      flex-basis: 45%;
      max-width: 45%;

      &:nth-child(odd) {
        margin-left: 0;
        margin-right: 10px;
      }
      &:nth-child(even) {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }
}
