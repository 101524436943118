/* -Company block that can show additional info in a modal
---------------------------------------------------------------------------- */
@use "sass:math";

$company-block-image-height: 120px;

.company-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  text-align: center;
}
.company-block-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $company-block-image-height;
  padding: 10px 20px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;

  .js .overlay-link:hover ~ & {
    border-color: #aaa;
  }
  .company-block--small & {
    height: 80px;
    padding: 10px 15px;
    border-radius: 7px;
  }
  img {
    max-height: 100%;
  }
}
.company-block-title {
  position: relative;
  margin: 10px 0 0;
  text-align: center;
  font-size: rem(16px);
  font-weight: $font-weight-bold;

  @include media-min(rem(500px)) {
    font-size: rem(20px);
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  .js &[tabindex]:hover,
  .js &[tabindex]:focus,
  .js .overlay-link:hover ~ & {
    text-decoration: underline;
  }
}

/* ------------------- List of blocks ------------------- */
.company-list {
  @include section-padding-bottom;
  @include grid-container;
  @include grid-pull-bottom;

  display: flex;
  flex-wrap: wrap;
}
.company-list-block {
  @include grid-item(1);
  @include grid-margin-bottom;

  display: inline-flex;

  @include media-min(rem(380px)) {
    @include grid-item-size(1 * 0.5);
  }
  @include media-min(rem(650px)) {
    @include grid-item-size(math.div(1, 3));
  }
  @include media-min(rem(950px)) {
    @include grid-item-size(1 * 0.25);
  }
}
.company-list-no-results {
  @include grid-item(1);
  @include grid-margin-bottom;

  text-align: center;
}

/* ------------------- Block in card ------------------- */
.company-card {
  .company-block {
    flex-grow: 1;
    justify-content: normal;
  }
  .company-block-image {
    height: auto;
    flex-grow: 1;

    img {
      max-height: $company-block-image-height;
    }
  }
}
