/* -Company information
---------------------------------------------------------------------------- */
.company-info {
  width: 100%;
  border: 1px solid $color-gray-light;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
}

.company-info-image {
  padding: 20px 30px;
  border-bottom: 1px solid $color-gray-light;
  text-align: center;

  img {
    max-height: 250px;
  }
}

.company-info-text {
  padding: 15px 20px;

  @include media-min(rem(600px)) {
    padding: 25px 30px;
  }
}

.company-info-data {
  margin: 0;
  // For word-wrap to work
  table-layout: fixed;

  th,
  td {
    padding-top: 5px;
    vertical-align: top;
  }
  th {
    padding-right: 1px;
    font-weight: inherit;
    text-align: left;
    white-space: nowrap;

    // Dotted line
    div {
      display: flex;

      &::after {
        content: '';
        display: inline-block;
        flex-grow: 1;
        min-width: 10px;
        height: 1.1em;
        margin-left: 3px;
        border-bottom: 1px dotted;

        @include media-min(rem(450px)) {
          min-width: 25px;
        }
        @include media-min(rem(800px)) {
          min-width: 40px;
        }
      }
    }
  }
  td {
    font-weight: $font-weight-semibold;
    word-wrap: break-word;
  }
  @include media-max(rem(400px)) {
    font-size: rem(14px);
  }
  @include media-min(rem(600px)) {
    font-size: rem(20px);
  }
}

.company-info-link {
  margin: 15px 0 0;
  font-size: rem(24px);
}
