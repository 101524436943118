/* -Breadcrumb links
---------------------------------------------------------------------------- */
$breadcrumb-item-spacing: 30px;

.breadcrumb {
  @include section-margin-bottom(1 * 0.5);

  font-size: rem(14px);

  li {
    display: inline-block;

    &:not(:last-child) {
      position: relative;
      margin-right: $breadcrumb-item-spacing;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: -(($breadcrumb-item-spacing * 0.5) + 2);
        width: 7px;
        height: 7px;
        margin-top: 1px;
        border-top: 1px solid;
        border-right: 1px solid;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
  .active a {
    color: $color-lightest-accessible-gray-on-white;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
