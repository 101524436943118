/* -Park overview page
---------------------------------------------------------------------------- */
@use "sass:math";

$park-overview-block-spacing: 20px;

.park-overview-wrap {
  @include section-margin-bottom;
}

.park-overview {
  @include grid-container;

  margin-bottom: -50px;
}

.park-overview-part {
  @include grid-item(1);

  // Match two columns with the intro content size.
  max-width: ($wrap-width-thin + rem($grid-gutter)) * 0.5;
  margin-bottom: 50px;

  @include media-min(rem(700px)) {
    @include grid-item(1 * 0.5);
  }
  @include media-min(rem(1000px)) {
    .park-overview--cols-3 & {
      @include grid-item-size(math.div(1, 3));
    }
  }
}

.park-overview-part-title {
  margin-bottom: 15px;
}

.park-overview-more-link {
  margin: 0;
}

/* ------------------- Events ------------------- */
.park-overview-event {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: $park-overview-block-spacing;
  padding-left: 100px;
  min-height: rem(80px);
}

.park-overview-event-date {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  padding: 5px;
  background-color: $color-gray-light;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.park-overview-event-title,
.park-overview-event-organizer {
  padding-left: 10px;
}

.park-overview-event-title {
  font-size: inherit;
  font-weight: inherit;

  a:link,
  a:visited {
    text-decoration: underline;
  }
  .overlay-link:hover ~ &,
  .overlay-link:focus ~ & {
    a {
      text-decoration: none;
    }
  }
}

.park-overview-event-organizer {
  display: inline-block;
  font-size: rem(13px);
}

/* ------------------- Companies ------------------- */
.park-overview-company-list {
  @include grid-container($park-overview-block-spacing);
}
.park-overview-company {
  @include grid-item(1 * 0.5, $park-overview-block-spacing);
  @include grid-margin-bottom($park-overview-block-spacing);
}

/* ------------------- Service ------------------- */
.park-overview-service-links {
  ul {
    margin-bottom: 1em;
  }
  li {
    margin-bottom: 10px;
  }
}

/* ------------------- Notices (info and service) ------------------- */
$notice-image-size: 50px;

.park-notices {
  & ~ .park-overview-event-list,
  & ~ .park-overview-service-links {
    // The margin is against a straight edge while the padding is against text,
    // they look different visually.
    margin-top: 35px;
    padding-top: 30px;
    border-top: 1px solid #ccc;
  }
  & ~ .park-overview-event-list {
    // The events have top spacing and have a straight edge
    padding-top: 35px - $park-overview-block-spacing;
  }
  &:empty {
    display: none;
  }
}

.park-notice {
  margin-bottom: $park-overview-block-spacing;
}
.park-notice-image {
  float: left;
  position: relative;
  margin-right: 10px;
  width: $notice-image-size;

  &::after {
    content: '';
    display: block;
    position: absolute;
    // Half image height minus half border arrow size
    top: round(($notice-image-size / 2) - 5px);
    left: 100%;
    width: 0;
    height: 0;
    border: 5px solid transparent;

    .park-notice--info & {
      border-right-color: $color-notice-info;
    }
    .park-notice--service & {
      border-right-color: $color-notice-service;
    }
  }
  img {
    border-radius: 50%;
    overflow: hidden;
  }
}
.park-notice-text {
  margin: 0;
  padding: 12px 15px;
  border-radius: 10px;
  overflow: hidden;

  .park-notice--info & {
    background: $color-notice-info;
  }
  .park-notice--service & {
    background: $color-notice-service;
  }
}
