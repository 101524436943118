/* -Page sub navigatino
---------------------------------------------------------------------------- */
.sub-nav {
  font-size: $base-font-size;

  li {
    margin-top: 0.5em;
  }
  a {
    display: inline-block;
    padding: 3px 0;
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  // First level
  > ul > li {
    margin-top: 0.75em;

    > a {
      font-size: rem(18px);
      font-weight: $font-weight-semibold;
    }

    // Top level page
    &:first-child {
      margin-top: 0;
      margin-bottom: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid #dedede;

      > a {
        font-size: rem(22px);
        font-weight: $font-weight-bold;
      }
    }
  }
  .sub-menu {
    padding-left: 2em;
  }
  .current-item > a {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0.9em;
      right: 100%;
      width: 10px;
      height: 2px;
      margin-right: 0.5em;
      background: currentColor;
    }
  }
}
