/* -Rich text output
-----------------------------------------------------------------------------*/
.rich-text {
  // Remove bottom margins from the last text elements of the last rich text
  &:last-child {
    &.lead-text {
      margin-bottom: 0;
    }
    p,
    ol,
    ul {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
