/* -'Images' block
-----------------------------------------------------------------------------*/
@use "sass:math";

$images-block-vertical-gutter: rem($text-spacing);

.images-block {
  margin-top: $images-block-vertical-gutter;
  margin-bottom: $images-block-vertical-gutter;

  &:first-child {
    margin-top: 0;
  }
}

.images-block-item {
  > a {
    display: block;
  }
}

.images-block-item-caption {
  .images-block--cols-1 &,
  .images-block--cols-2 &,
  .images-block--cols-3 &,
  .images-block--cols-4 &,
  .images-block--gallery & {
    margin-top: 5px;
    font-size: rem(14px);

    p {
      margin-bottom: 0.5em;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.images-block-item-image {
  a {
    transition: opacity 0.15s ease;

    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }
}

/*-------------------- Columns layout --------------------*/
.images-block--cols-1 {
  &:last-child {
    margin-bottom: 0;
  }
}

.images-block--cols-2 {
  margin-bottom: 0;

  &:last-child {
    margin-bottom: -$images-block-vertical-gutter;
  }
  .images-block-item {
    margin-bottom: $images-block-vertical-gutter;
  }

  @include media-min(rem(400px)) {
    @include grid-container;

    .images-block-item {
      @include grid-item(1 * 0.5);
    }
  }
}
.images-block--cols-3 {
  margin-bottom: 0;

  &:last-child {
    margin-bottom: -$images-block-vertical-gutter;
  }
  .images-block-item {
    margin-bottom: $images-block-vertical-gutter;
  }

  @include media-min(rem(400px)) {
    @include grid-container;

    .images-block-item {
      @include grid-item(math.div(1, 3));
    }
  }
}

.images-block--cols-4 {
  margin-bottom: 0;

  &:last-child {
    margin-bottom: -$images-block-vertical-gutter;
  }
  .images-block-item {
    margin-bottom: $images-block-vertical-gutter;
  }
  @include media-min(rem(400px)) {
    @include grid-container(
      $images-block-vertical-gutter,
      $images-block-vertical-gutter
    );

    .images-block-item {
      @include grid-item(
        1 * 0.25,
        $images-block-vertical-gutter,
        $images-block-vertical-gutter
      );
    }
  }
}

/*-------------------- Gallery layout --------------------*/
$images-block-gallery-gutter: 30px;
.images-block--gallery {
  @include grid-container(
    $images-block-gallery-gutter,
    $images-block-gallery-gutter * 0.5
  );

  margin-bottom: 0;

  &:last-child {
    @include grid-pull-bottom(
      $images-block-gallery-gutter,
      $images-block-gallery-gutter * 0.5
    );
  }
  .images-block-item {
    @include grid-item(
      1 * 0.25,
      $images-block-gallery-gutter,
      $images-block-gallery-gutter * 0.5
    );
    @include grid-margin-bottom(
      $images-block-gallery-gutter,
      $images-block-gallery-gutter * 0.5
    );
  }
  .images-block-item:first-child,
  .images-block-item:first-child + .images-block-item {
    @include grid-item-size(1 * 0.5);
  }
}

/*-------------------- Text-to-the-side layout --------------------*/
.images-block--side-text {
  .images-block-item-caption {
    margin-top: 10px;
  }

  @include media-min(rem(550px)) {
    .images-block-item {
      @include grid-container;

      &:nth-child(even) {
        flex-direction: row-reverse;
      }
    }
    .images-block-item-image,
    .images-block-item-caption {
      @include grid-item(1 * 0.5);
    }
    .images-block-item-caption {
      margin-top: 0;
    }
  }
}
