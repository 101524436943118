/* -Global bits and pieces
-----------------------------------------------------------------------------*/
html {
  scroll-behavior: smooth;

  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}
// Class handled with JavaScript, which tries to take accessibility in mind.
// Sets hide-focus when a mouse is used and show focus when a keyboard is.
// Hide outlines for mouse users and make it more distinct for keyboard users.
a,
button,
input[type='submit'],
input[type='button'],
input[type='reset'],
[tabindex] {
  .hide-focus & {
    outline: 0 !important;
  }
  .show-focus &:focus {
    outline: 3px dotted $color-body-foreground;
    outline-offset: 1px;
  }
  .show-focus .site-footer &:focus {
    outline-color: $color-footer-foreground;
  }
  .show-focus .lightbox &:focus {
    outline-color: #fff;
  }
  .show-focus .lightbox--company &:focus {
    outline-color: $color-body-foreground;
  }
}

/* ------------------- Lokalguiden iframe ------------------- */
iframe[src*='lokalguiden'] {
  width: 100%;
}

/* ------------------- 404 and 500 pages ------------------- */
.error-page-content {
  margin: 80px 20px 0;
  min-height: 80vh;

  @include media-max($breakpoint-grid-tight) {
    margin: 40px 0;
  }
}

/* --------- Wagtail userbar in bottom right for admins --------- */
// Move to not cover CTA-button when used

.has-cta-link ~ .wagtail-userbar-reset {
  .wagtail-userbar--bottom-right {
    bottom: em(80px);
  }
}
