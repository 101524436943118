/* -Headings
-----------------------------------------------------------------------------*/
@use "sass:math";

@mixin h {
  margin-bottom: 5px;
  font-family: $font-stack-main;
  font-weight: $font-weight-bold;
  line-height: $heading-line-height;
  text-transform: uppercase;

  a:link,
  a:visited {
    color: inherit;
    text-decoration: none;
  }
  a:hover,
  a:focus {
    color: $color-action;
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include h;
}

@mixin h1 {
  font-size: rem($h1);
  @include media-min($breakpoint-type-medium) {
    line-height: $h1-line-height;
  }
}
@mixin h2 {
  font-size: rem($h2);
  @include media-min($breakpoint-type-medium) {
    line-height: $h2-line-height;
  }
}
@mixin h3 {
  font-size: rem($h3);
}
@mixin h4 {
  font-size: rem($h4);
}
@mixin h5 {
  font-size: rem($h5);
}
@mixin h6 {
  font-size: rem($h6);
}

%h1 {
  @include h1;
}
%h2 {
  @include h2;
}
%h3 {
  @include h3;
}
%h4 {
  @include h4;
}
%h5 {
  @include h5;
}
%h6 {
  @include h6;
}

h1,
.h1 {
  @extend %h1;
}
h2,
.h2 {
  @extend %h2;
}
h3,
.h3 {
  @extend %h3;
}
h4,
.h4 {
  @extend %h4;
}
h5,
.h5 {
  @extend %h5;
}
h6,
.h6 {
  @extend %h6;
}

.h-upper {
  text-transform: uppercase;
}

.rich-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      margin-top: 0 !important;
    }
  }
  h1,
  .h1 {
    margin-top: #{math.div($base-text-spacing, $h1)}em;
  }
  h2,
  .h2 {
    margin-top: #{math.div($base-text-spacing, $h2)}em;
  }
  h3,
  .h3 {
    margin-top: #{math.div($base-text-spacing, $h3)}em;
  }
  h4,
  .h4 {
    margin-top: #{math.div($base-text-spacing, $h4)}em;
  }
  h5,
  .h5 {
    margin-top: #{math.div($base-text-spacing, $h5)}em;
  }
  h6,
  .h6 {
    margin-top: #{math.div($base-text-spacing, $h6)}em;
  }
}

@include media-max($breakpoint-type-medium) {
  %h1 {
    font-size: rem($h1 - 12px);
  }
  %h2 {
    font-size: rem($h2 - 8px);
  }
  %h3 {
    font-size: rem($h3 - 2px);
  }
}

@include media-max($breakpoint-type-small) {
  %h1 {
    font-size: rem($h1 - 24px);
  }
  %h2 {
    font-size: rem($h2 - 10px);
  }
  %h3 {
    font-size: rem($h3 - 4px);
  }
}

@include media-max($breakpoint-type-tiny) {
  %h1 {
    font-size: rem($h1 - 26px);
  }
  %h2 {
    font-size: rem($h2 - 12px);
  }
}
