/* -'Image and content' block
-----------------------------------------------------------------------------*/
$image-content-gutter-size: 80px;
$image-content-large-max-size: 1700px;

.image-content-block {
  @include section-margin;
  @include large-content-text;
}

.image-content-block-media {
  display: flex;
  flex-direction: column;
}
.image-content-block-media-inner {
  flex-grow: 1;
  position: relative;
}
.image-content-block-media .video-overlay-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
// Can either have a background image or be a container for a video.
.image-content-block-aspect-ratio {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.image-content-block-media--video {
  video {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.image-content-block-text {
  margin-bottom: $text-spacing;
}

.image-content-block--small {
  .image-content-block-media {
    margin-bottom: 10px;
  }
  .image-content-block-aspect-ratio {
    @include aspect-ratio('3:2');
  }

  @include media-min(rem(750px)) {
    display: flex;
    align-items: flex-start;

    .image-content-block-media {
      flex-basis: 40%;
    }
    .image-content-block-content {
      flex-basis: 60%;
    }
    &:nth-child(2n + 1) {
      .image-content-block-media {
        margin-right: $grid-gutter;
      }
    }
    &:nth-child(2n) {
      flex-direction: row-reverse;

      .image-content-block-media {
        margin-left: $grid-gutter;
      }
    }
  }
  @include media-min(rem(900px)) {
    .image-content-block-media,
    .image-content-block-content {
      flex-basis: 50%;
    }
  }
}

.image-content-block--large {
  max-width: $image-content-large-max-size;
  margin-left: auto;
  margin-right: auto;

  .image-content-block-aspect-ratio {
    @include aspect-ratio('16:9');
  }
  .image-content-block-content {
    padding: 20px;
  }

  @include media-min(rem(850px)) {
    display: flex;
    margin-top: 0;
    margin-bottom: 0;

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }
    .image-content-block-media,
    .image-content-block-content {
      flex: 1 0 50%;
    }
    .image-content-block-aspect-ratio {
      @include aspect-ratio('4:3');

      min-height: 100%;
    }
    .image-content-block-content {
      display: flex;
      flex-direction: column;
    }
    .image-content-block-text {
      flex-grow: 1;
    }
  }

  @include media-min(rem(1000px)) {
    .image-content-block-content {
      padding: 30px;
    }
  }

  @include media-min(rem(1300px)) {
    .image-content-block-content {
      padding: 40px;
    }
    .image-content-block-aspect-ratio {
      @include aspect-ratio('16:10');
    }
  }

  @include media-min(rem(1600px)) {
    .image-content-block-aspect-ratio {
      @include aspect-ratio('16:9');
    }
  }

  @include media-min($image-content-large-max-size) {
    @include section-margin(1.5);

    &:nth-child(2n) {
      .image-content-block-media {
        padding-left: 40px;
      }
    }
    &:nth-child(2n + 1) {
      .image-content-block-media {
        padding-right: 40px;
      }
    }
  }
}
