/* -Into image with content
---------------------------------------------------------------------------- */

// Keep duration in sync with Intro.js
$intro-animation-duration: 4000ms;
$intro-animation-pan: 2%;
$intro-animation-scale: 1.1;

$initial-intro-transform: translate(0, 0) scale(1);
@keyframes intro-top-left {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate(-$intro-animation-pan, -$intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-top-right {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate($intro-animation-pan, -$intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-bottom-right {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate($intro-animation-pan, $intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-bottom-left {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate(-$intro-animation-pan, $intro-animation-pan)
      scale($intro-animation-scale);
  }
}

// Must have a wrapping column flex container for IE11
// https://github.com/philipwalton/flexbugs#flexbug-3
.intro-container {
  display: flex;
  flex-direction: column;
}

.intro {
  @include large-content-text;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  overflow: hidden;

  @media screen and (orientation: landscape) {
    min-height: 50vh;

    .media-block-container & {
      height: 65vh;
      max-height: 800px;
    }
  }
  @media screen and (orientation: portrait) {
    min-height: 35vh;
  }
}
.intro--large {
  min-height: calc(100vh - #{rem(140px)});

  @include media-max($breakpoint-menu-toggle) {
    min-height: 50vh;
  }
}

.intro-bg {
  // Ensure text is readable if image or video fails to load
  background-color: $color-brand-gray;

  &,
  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  // Ensure the first element is on top to prevent flashing when Flickity is
  // initiated, since it sets the first slide as selected. This will only
  // target non-Flickity picture since wrapping divs are added for the slider.
  > picture:first-child {
    z-index: 2;
  }
}
.intro-image-active {
  // Add buffer to keep the animation going as the active slide changes
  animation-duration: $intro-animation-duration + 2000ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  &[data-direction='top-left'] {
    animation-name: intro-top-left;
  }
  &[data-direction='top-right'] {
    animation-name: intro-top-right;
  }
  &[data-direction='bottom-right'] {
    animation-name: intro-bottom-right;
  }
  &[data-direction='bottom-left'] {
    animation-name: intro-bottom-left;
  }
}

.intro-content-center {
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 60px;
  color: #fff;
  text-align: center;
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 10;

  @include media-min(rem(650px)) {
    padding-top: 180px;
    padding-bottom: 120px;
  }

  h1 {
    margin-bottom: 0.5em;

    @include media-min(rem(350px)) {
      font-size: rem(50px);
    }
    @include media-min(rem(650px)) {
      font-size: rem(80px);
    }
    @include media-min(rem(950px)) {
      font-size: rem(100px);
    }
    @include media-min(rem(1200px)) {
      font-size: rem(125px);
    }
  }
  .link-block {
    margin-bottom: 0;
  }
}
.intro-content-bottom {
  position: relative;
  margin-bottom: $page-margin;
  margin-top: auto;
  z-index: 10;
  color: #fff;

  @include when-page-margin-small {
    margin-bottom: $page-margin-small;
  }
}
.intro-content-center,
.intro-content-bottom {
  @include media-min(rem(650px)) {
    font-size: rem(20px);
  }
  @include media-min(rem(950px)) {
    font-size: rem(25px);
  }
  @include media-min(rem(1200px)) {
    font-size: rem(30px);
  }
}

.intro-section {
  padding: 10px 15px;

  @include media-min(rem(950px)) {
    padding: 15px 30px;
  }

  .block-content,
  .form-block {
    @include page-margin-reset;
    max-width: $wrap-width-thin;
  }
}
.intro-section--white {
  background-color: rgba(255, 255, 255, 0.75);
  color: $color-gray-dark;
}
.intro-section--gray {
  background-color: rgba($color-gray-dark, 0.9);
  color: #fff;

  a {
    color: inherit;
  }
}
