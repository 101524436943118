/* -News and events selection based on a tag
---------------------------------------------------------------------------- */
.news-events-selection {
  @include section-padding;
}

.news-events-selection-title {
  margin-left: $card-slider-title-width + $card-slider-spacer-width;
  margin-bottom: round($section-spacing / 4);
}
