/* -Highlight pages block
---------------------------------------------------------------------------- */
.highlight-pages {
  @include section-margin;
}

.highlight-pages-content {
  @include section-margin-bottom;
  @include large-content-text;

  text-align: center;
}

.highlight-pages-link {
  @include section-margin-top;

  text-align: center;
}
