/* -Generic card used for news, events and highlight pages
---------------------------------------------------------------------------- */
@use "sass:math";

.page-card {
  position: relative;
  max-width: 500px;
}

.page-card-inner {
  position: relative;
  width: 100%;

  .page-card--has-bg .overlay-link:hover ~ &,
  .page-card--has-bg .overlay-link:focus ~ & {
    box-shadow: inset 0 0 0 2px $color-body-foreground;
  }
}

.page-card-image {
  position: relative;
  // https://github.com/philipwalton/flexbugs#flexbug-5
  // https://github.com/philipwalton/flexbugs/issues/75#issuecomment-161800607
  min-height: 1px;
  transition: opacity 0.15s ease;

  img {
    width: 100%;
  }
}
.page-card-image--top {
  margin-bottom: 15px;
}
.page-card-image--bottom {
  margin-top: 15px;
}
.page-card-image-fallback {
  // Match image size defined in the template
  padding-bottom: percentage(math.div(300, 500));
  background: rgba(0, 0, 0, 0.05);
}

.page-card-date {
  // Date on image
  .date-badge {
    position: absolute;
    bottom: 0;
    right: 20px;
  }
}

.page-card-content {
  border-bottom: 2px solid transparent;

  .page-card--no-bg .overlay-link:hover ~ .page-card-inner &,
  .page-card--no-bg .overlay-link:focus ~ .page-card-inner & {
    border-bottom-color: $color-body-foreground;
  }

  @include media-max(rem(700px)) {
    font-size: rem(14px);
  }
}

.page-card-title {
  margin-bottom: 10px;
  font-size: rem(22px);
}

.page-card-excerpt {
  margin-bottom: 1em;
}

.page-card-organizer {
  margin: 0;
  font-weight: $font-weight-semibold;
}
.page-card-organizer-label {
  font-size: rem(13px);
}
.page-card-organizer-text {
  display: block;
}

// Card with background
.page-card--has-bg {
  .page-card-inner {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #fff;
  }

  &.page-card--has-image {
    .page-card-title {
      // 3 lines
      min-height: $heading-line-height * 3em;
    }
  }
  &.page-card--no-image {
    .page-card-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
    .page-card-date {
      margin-top: auto;
    }
  }
}

@include media-max(rem(450px)) {
  .page-card--has-bg .page-card-inner {
    padding: 15px;
  }
  .page-card-title {
    font-size: rem(20px);
  }
}

/* --------------- List of page cards --------------- */
// Also handles company-card for search results.

.page-cards-wrap {
  @include wrap-custom(rem(400px));

  // Inside a page with a sub menu, already has page margin
  .page-main-body--has-nav & {
    @include page-margin-reset;
  }
}

.page-cards {
  @include grid-container;
  @include grid-pull-bottom;

  display: flex;
  flex-wrap: wrap;

  .page-card,
  .company-card {
    @include grid-item(1);
  }
  .company-card {
    @include grid-margin-bottom();
  }
  .page-card {
    // Make space for theme flag
    @include grid-margin-bottom($grid-gutter, $grid-gutter-tight + 15px);
  }
  .page-card .overlay-link {
    @include grid-gutter-rule(left);
  }
  .page-card--has-bg,
  .company-card {
    display: flex;
    flex-direction: column;
  }
  .page-card--has-bg .page-card-inner {
    flex-grow: 1;
    min-height: 200px;
  }
}

@include media-min(rem(650px)) {
  .page-cards-wrap--cols-1 {
    @include wrap-width-custom(rem(450px));
  }
  .page-cards-wrap--cols-2,
  .page-cards-wrap--cols-3,
  .page-cards-wrap--cols-4 {
    @include wrap-width-thin;

    .page-card,
    .company-card {
      @include grid-item-size(1 * 0.5);
    }
  }
}

@include media-min(rem(1100px)) {
  .page-cards-wrap--cols-3,
  .page-cards-wrap--cols-4 {
    @include wrap-width-wide;

    .page-card,
    .company-card {
      @include grid-item-size(math.div(1, 3));
    }
  }
}


/* --------------- Event page card in card slider --------------- */

/* Use margin bottom to add extra height for absolute positioned theme mark. The
slider has overflow:hidden and position:relative set so we can't have element
positioned outside the slider.
*/

.event-slider {
  .page-card {
    flex-direction: column;
  }
  .overlay-link {
    bottom: $card-slider-theme-mark-height - rem(4px);
  }
  .page-card-inner {
    margin-bottom: $card-slider-theme-mark-height;
    flex-grow: 1;
  }
}
