/* -Buttons
-----------------------------------------------------------------------------*/

/*---------- Mixins ----------*/
@mixin button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  border: 0;
  border-radius: $form-control-radius;
  font-weight: $font-weight-regular;
  font-size: rem($button-font-size);
  text-decoration: none;
  text-align: center;
  text-shadow: none;
  vertical-align: middle;
  box-shadow: none;
  transition: background-color 0.15s ease-out;

  // Fixed height for buttons in forms to match the height of input fields
  form & {
    height: $button-height;
    padding-top: 0;
    padding-bottom: 0;
    line-height: $button-height - em(2px, $button-font-size);
  }
}

@mixin button-primary-base-colors {
  background-color: $color-action;
  color: #fff;
}
@mixin button-primary-colors {
  @include button-primary-base-colors;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: lighten($color-action, 15%);
    }
  }
}
@mixin button-primary {
  @include button;
  @include button-primary-colors;

  &:disabled {
    opacity: 0.5;
  }
}

@mixin button-secondary-base-colors {
  background-color: $color-gray-dark;
  color: #fff;
}
@mixin button-secondary-colors {
  @include button-secondary-base-colors;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: lighten($color-gray-dark, 20%);
    }
  }
}
@mixin button-secondary {
  @include button;
  @include button-secondary-colors;

  &:disabled {
    opacity: 0.5;
  }
}

@mixin button-gray-colors {
  @include color-theme-brand-gray;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: lighten($color-brand-gray, 15%);
    }
  }
}
@mixin button-gray {
  @include button;
  @include button-gray-colors;

  &:disabled {
    opacity: 0.5;
  }
}

@mixin button-light-base-colors {
  background-color: $color-gray-light;
  color: $color-body-foreground;
}
@mixin button-light-colors {
  @include button-light-base-colors;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: darken($color-gray-light, 15%);
    }
  }
}
@mixin button-light {
  @include button;
  @include button-light-colors;

  &:disabled {
    opacity: 0.5;
  }
}

@mixin button-white-base-colors {
  background-color: #fff;
  color: $color-body-foreground;
}
@mixin button-white-colors {
  @include button-white-base-colors;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: darken(#fff, 15%);
    }
  }
}
@mixin button-white {
  @include button;
  @include button-white-colors;

  &:disabled {
    opacity: 0.5;
  }
}

@mixin button-green-colors {
  @include color-theme-brand-green;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: lighten($color-brand-green, 5%);
    }
  }
}
@mixin button-green {
  @include button;
  @include button-green-colors;

  &:disabled {
    opacity: 0.5;
  }
}

@mixin button-blue-colors {
  @include color-theme-brand-blue;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: lighten($color-brand-blue, 5%);
    }
  }
}
@mixin button-blue {
  @include button;
  @include button-blue-colors;

  &:disabled {
    opacity: 0.5;
  }
}

@mixin button-yellow-colors {
  @include color-theme-brand-yellow;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: lighten($color-brand-yellow, 10%);
    }
  }
}
@mixin button-yellow {
  @include button;
  @include button-yellow-colors;

  &:disabled {
    opacity: 0.5;
  }
}

@mixin button-orange-colors {
  @include color-theme-brand-orange;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: lighten($color-brand-orange, 5%);
    }
  }
}
@mixin button-orange {
  @include button;
  @include button-orange-colors;

  &:disabled {
    opacity: 0.5;
  }
}

@mixin button-black-colors {
  @include color-theme-black;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: lighten(#000, 15%);
    }
  }
}
@mixin button-black {
  @include button;
  @include button-black-colors;

  &:disabled {
    opacity: 0.5;
  }
}

/*---------- Standard button classes ----------*/
.btn,
a.btn,
button,
input[type='submit'],
input[type='button'],
input[type='reset'] {
  @include button-primary;

  &:disabled {
    cursor: default;
  }
  > span {
    vertical-align: middle;
  }
  .icon,
  .spinner {
    &:first-child {
      margin-right: 0.5em;
    }
    &:last-child {
      margin-left: 0.5em;
    }
    &:only-child {
      margin-left: 0;
      margin-right: 0;
      vertical-align: top;
    }
  }
}

a.btn-secondary,
.btn-secondary {
  @include button-secondary;
}

a.btn-gray,
.btn-gray {
  @include button-gray();
}

a.btn-light,
.btn-light {
  @include button-light;
}

a.btn-white,
.btn-white {
  @include button-white;
}

a.btn-green,
.btn-green {
  @include button-green;
}
a.btn-blue,
.btn-blue {
  @include button-blue;
}
a.btn-yellow,
.btn-yellow {
  @include button-yellow;
}
a.btn-orange,
.btn-orange {
  @include button-orange;
}
a.btn-black,
.btn-black {
  @include button-black;
}

// ----- Variants -----
.btn--large-type,
a.btn--large-type {
  font-weight: $font-weight-semibold;

  @include media-min(rem(500px)) {
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: rem(20px);
  }
}

.btn--small,
a.btn--small {
  padding: 5px 10px;
}

@mixin btn-large {
  padding: 13px 30px;
  font-size: rem(20px);
  font-weight: $font-weight-semibold;
}
.btn--large,
a.btn--large {
  @include btn-large();
}

// ----- Special use case buttons -----

// Semantically a button but doesn't have any styles to indicate it
@mixin btn-neutral {
  height: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  background: transparent !important;
  color: inherit !important;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}
.btn-neutral {
  @include btn-neutral;

  .no-js & {
    cursor: default;
  }
}

// Button that looks like a regular link
@mixin btn-plain {
  @include btn-neutral;

  text-decoration: underline;
  color: $color-action;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.btn-plain {
  @include btn-plain;

  .no-js & {
    cursor: default;
  }
}
